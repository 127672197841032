import {
  Avatar,
  Button,
  Form,
  Image,
  Input,
  Modal,
  Rate,
  Spin,
  Upload,
  UploadFile,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  Unsubscribe,
  addDoc,
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { nanoid } from "nanoid";
import { useCallback, useEffect, useRef, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import { FaCheck, FaRegFilePdf } from "react-icons/fa";
import { IoMdPaperPlane } from "react-icons/io";
import {
  MdOutlinePermMedia,
  MdOutlineSend,
  MdStarRate,
  MdSupportAgent,
} from "react-icons/md";
import { Navigate } from "react-router-dom";
import { Player } from "video-react";
import { PrivateRoute } from "../components/Route";
import { useRequest } from "../hooks/useRequest";
import { useTitle } from "../hooks/useTitle";
import { db, storage } from "../lib/firebase";
import { ISession } from "../lib/model";
import { getBase64, getDomain } from "../lib/utils";
import { useCTX } from "../state";
import { setUser } from "../state/actionCreators";

export default function Support() {
  useTitle("Support");

  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState<ISession | null>(null);
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [rateSession, setRateSession] = useState(false);
  const [rate, setRate] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const unsubRef = useRef<{ [k: number]: Unsubscribe }>({});

  const {
    state: { user },
    dispatch,
  } = useCTX();
  const [renewRequest, renewing] = useRequest();
  const [rateRequest, rating] = useRequest();
  const [sessionRequest] = useRequest();

  const onSubmit = useCallback(
    async (values: any) => {
      setSubmitting(true);

      const cref = collection(
        db,
        "SupportChat",
        `${user?.id}_support@${getDomain()}`,
        "messages"
      );
      const message = await addDoc(cref, {
        senderMail: user?.email,
        receiverMail: "support@yunee.app",
        agentMail: "",
        messageType: "text",
        body: values.description,
        imageURL: "",
        audioURL: "",
        videoURL: "",
        documentURL: "",
        sessionId: 0,
        active: false,
        domain: getDomain(),
        timestamp: new Date().getTime().toString(),
      });

      const lref = collection(db, "SupportChat", "support@yunee.app", "logs");
      await addDoc(lref, {
        type: "REQUEST",
        providerId: user?.id,
        messageId: message.id,
        domain: getDomain(),
        timestamp: new Date().getTime().toString(),
      });

      dispatch(setUser({ ...user!, support_status: "R" }));

      setSubmitting(false);
    },
    [user, dispatch]
  );

  const onNew = useCallback(async () => {
    const _info = { email: user?.email, support_status: "N" };

    renewRequest(
      "post",
      "providers/api/providerProfileUpdate",
      () => {
        dispatch(setUser({ ...user!, support_status: "N" }));
        setSession(null);
      },
      _info
    );
  }, [user, dispatch, renewRequest]);

  const onMedia = useCallback(
    (file: UploadFile) => {
      setSending(true);

      const ftype = file.type?.split("/")[0];
      const fref = ref(
        storage,
        `SupportChat/${user?.id}_support@${getDomain()}/${ftype}s/${nanoid(
          5
        )}.${file.type?.split("/")[1]}`
      );

      const uploader = uploadBytesResumable(fref, file.originFileObj!);

      uploader.on("state_changed", null, null, () => {
        getDownloadURL(uploader.snapshot.ref).then((url) => {
          const cref = collection(
            db,
            "SupportChat",
            `${user?.id}_support@${getDomain()}`,
            "messages"
          );

          const message = {
            senderMail: user?.email,
            receiverMail: session?.agent.email,
            agentMail: session?.agent.email,
            messageType: ftype,
            body: "",
            imageURL: "",
            audioURL: "",
            videoURL: "",
            documentURL: "",
            sessionId: session?.id,
            active: true,
            domain: getDomain(),
            timestamp: new Date().getTime().toString(),
          };

          if (ftype === "image") message.imageURL = url;
          else if (ftype === "audio") message.audioURL = url;
          else if (ftype === "video") message.videoURL = url;
          else if (ftype === "application") message.documentURL = url;

          addDoc(cref, message).then(() => {
            setSending(false);
          });
        });
      });
    },
    [user, session]
  );

  const onMessage = useCallback(async () => {
    setSending(true);
    setMessage("");

    const cref = collection(
      db,
      "SupportChat",
      `${user?.id}_support@${getDomain()}`,
      "messages"
    );

    await addDoc(cref, {
      senderMail: user?.email,
      receiverMail: session?.agent.email,
      agentMail: session?.agent.email,
      messageType: "text",
      body: message,
      imageURL: "",
      audioURL: "",
      videoURL: "",
      sessionId: session?.id,
      active: true,
      domain: getDomain(),
      timestamp: new Date().getTime().toString(),
    });

    setSending(false);
  }, [message, user, session]);

  const onRate = useCallback(() => {
    rateRequest(
      "post",
      "agents/api/webrating/",
      () => {
        setSession({ ...session!, prov_rate: rate });
        setRate(0);
        setRateSession(false);
      },
      {
        token: localStorage.getItem("token"),
        sessionId: session?.id,
        rater: "provider",
        rating: rate,
      }
    );
  }, [session, rate, rateRequest]);

  useEffect(() => {
    if (!subscribed && !loading) {
      const lref = collection(db, "SupportChat", "support@yunee.app", "logs");

      const q = query(
        lref,
        where("type", "==", "ASSIGN"),
        where("providerId", "==", user?.id),
        where("providerRead", "==", false)
      );

      unsubRef.current[0] = onSnapshot(q, (snapshot) => {
        for (const c of snapshot.docChanges()) {
          if (c.type === "added") {
            updateDoc(c.doc.ref, { providerRead: true });

            if (!session) {
              dispatch(setUser({ ...user!, support_status: "A" }));
            }

            break;
          }
        }
      });

      setSubscribed(true);
    }
  }, [subscribed, loading, session, user, dispatch]);

  useEffect(() => {
    if (["N", "R"].includes(user?.support_status as any) || session) {
      if (loading) {
        setLoading(false);
      }
    } else {
      setLoading(true);

      const cref = collection(
        db,
        "SupportChat",
        `${user?.id}_support@${getDomain()}`,
        "messages"
      );

      let q = query(cref, orderBy("timestamp", "desc"));

      getDocs(q).then((snapshot) => {
        let docs = snapshot.docs.map((d) => ({
          id: d.id,
          ...d.data(),
        })) as any;

        sessionRequest(
          "post",
          "agents/api/websession/",
          (res) => {
            setSession({ ...res.data.session, messages: docs });

            q = query(
              cref,
              where("sessionId", "==", res.data.session.id),
              orderBy("timestamp", "asc")
            );

            unsubRef.current[1] = onSnapshot(q, (_snapshot) => {
              docs = _snapshot.docs.map((d) => ({
                id: d.id,
                ...d.data(),
              })) as any;

              setSession((old) => ({
                ...old!,
                status: docs.slice(-1)[0].active ? "A" : "F",
                messages: docs,
              }));

              if (!docs.slice(-1)[0].active) {
                dispatch(setUser({ ...user!, support_status: "W" }));
              }

              setLoading(false);
            });
          },
          {
            token: localStorage.getItem("token"),
            sessionId: docs[0].sessionId,
          }
        );
      });
    }
  }, [user, loading, session, dispatch, sessionRequest]);

  useEffect(() => {
    const ref = unsubRef.current;
    return () => Object.values(ref).forEach((u) => u());
  }, []);

  useEffect(() => {
    containerRef.current?.scrollTo({
      behavior: "smooth",
      top: containerRef.current.scrollHeight,
    });
  }, [session]);

  if (user?.prov_status !== "A") {
    return <Navigate to={"/"} />;
  }

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <Modal
        open={rateSession}
        title="Rate"
        onCancel={() => {
          setRateSession(false);
          setRate(0);
        }}
        okText="Submit"
        confirmLoading={rating}
        onOk={onRate}
      >
        <div className="flex justify-center">
          <Rate value={rate} onChange={setRate} />
        </div>
      </Modal>
      <div className="w-full">
        {user.support_status === "R" ? (
          <div className="flex flex-col items-center text-xs border border-solid border-gray-200 max-w-max mx-auto p-8 rounded-lg">
            <FaCheck className="text-4xl text-green-500 mb-2" />
            <span>Your request has been submitted,</span>
            <span>
              please be patient, an agent will contact you at the earliest.
            </span>
          </div>
        ) : user.support_status === "N" ? (
          <Form labelCol={{ span: 8 }} labelAlign="left" onFinish={onSubmit}>
            <Form.Item
              name="description"
              rules={[{ required: true, message: "Please input problem!" }]}
            >
              <TextArea rows={5} placeholder="How can I help you?" />
            </Form.Item>
            <div className="flex justify-end">
              <Button
                type="primary"
                className="text-xs"
                icon={<MdOutlineSend size={10} />}
                loading={submitting}
                htmlType="submit"
              >
                Submit
              </Button>
            </div>
          </Form>
        ) : session ? (
          <div className="w-[70%] border border-solid border-gray-100 mx-auto p-4">
            <div className="flex justify-between items-center h-[5vh]">
              <div className="flex">
                <Avatar
                  src={
                    session.agent.photo
                      ? session.agent.photo
                      : "/images/no-avatar.jpeg"
                  }
                  className="mr-2"
                />
                <div className="flex flex-col">
                  <span style={{ fontSize: "0.7rem" }}>
                    {session.agent.name}
                  </span>
                  <span
                    style={{ fontSize: "0.7rem" }}
                    className="text-gray-500"
                  >
                    {session.agent.email}
                  </span>
                </div>
              </div>
              {user.support_status === "W" && (
                <Button
                  className="text-xs"
                  icon={<MdSupportAgent size={10} />}
                  loading={renewing}
                  onClick={onNew}
                >
                  New
                </Button>
              )}
            </div>
            <div
              ref={containerRef}
              className="h-[58vh] py-4 mt-4 overflow-y-scroll"
            >
              <div className="flex flex-col justify-end">
                {(session.messages || []).map((m) => (
                  <div
                    key={m.id}
                    className="flex flex-col border border-solid border-gray-100 mt-4 p-4 w-1/2"
                    style={{
                      alignSelf:
                        user.email === m.senderMail ? "flex-end" : "flex-start",
                    }}
                  >
                    <span style={{ fontSize: "0.6rem" }} className="mb-2">
                      {m.senderMail}
                    </span>
                    {m.messageType === "image" ? (
                      <Image
                        src={m.imageURL}
                        alt={m.imageURL}
                        width={100}
                        height={100}
                        className="object-cover"
                      />
                    ) : m.messageType === "audio" ? (
                      <AudioPlayer src={m.audioURL} showJumpControls={false} />
                    ) : m.messageType === "video" ? (
                      <Player
                        src={m.videoURL}
                        fluid={false}
                        width={"100%" as any}
                        height={200}
                      />
                    ) : m.messageType === "application" ? (
                      <a
                        href={m.documentURL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaRegFilePdf className="text-5xl mt-2" />
                      </a>
                    ) : (
                      <span className="text-xs">{m.body}</span>
                    )}
                    <span
                      className="text-gray-500 mt-2 ml-auto"
                      style={{ fontSize: "0.7rem" }}
                    >
                      {new Date(parseInt(m.timestamp)).toLocaleTimeString()}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            {session.status === "A" ? (
              <div className="h-[5vh] flex items-center">
                <Upload
                  fileList={[]}
                  accept="image/*,audio/*,video/*,.pdf"
                  previewFile={getBase64 as any}
                  onChange={({ fileList }) => onMedia(fileList[0])}
                  className="mr-1"
                >
                  <Button icon={<MdOutlinePermMedia size={10} />} />
                </Upload>
                <Input
                  placeholder="Write a message..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <Button
                  className="ml-4"
                  icon={<IoMdPaperPlane size={10} />}
                  disabled={!message}
                  loading={sending}
                  onClick={onMessage}
                />
              </div>
            ) : (
              <div className="h-[5vh] flex items-center justify-center">
                <span className="font-bold" style={{ fontSize: "0.7rem" }}>
                  session has finished.
                </span>
                {typeof session.prov_rate !== "number" ? (
                  <Button
                    className="text-xs ml-2"
                    icon={<MdStarRate size={10} />}
                    onClick={() => setRateSession(true)}
                  >
                    Rate
                  </Button>
                ) : (
                  <Rate
                    disabled
                    className="text-xs ml-2"
                    value={session.prov_rate}
                  />
                )}
              </div>
            )}
          </div>
        ) : null}
      </div>
    </PrivateRoute>
  );
}
