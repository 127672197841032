import {
  Avatar,
  Button,
  Image,
  Input,
  List,
  Modal,
  Rate,
  Spin,
  Tag,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  Unsubscribe,
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { nanoid } from "nanoid";
import { useCallback, useEffect, useRef, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import { IoMdPaperPlane } from "react-icons/io";
import {
  MdClose,
  MdLogout,
  MdOutlinePermMedia,
  MdStarRate,
  MdSupportAgent,
} from "react-icons/md";
import { Navigate } from "react-router-dom";
import { Player } from "video-react";
import { PrivateRoute } from "../../components/Route";
import { useRequest } from "../../hooks/useRequest";
import { useTitle } from "../../hooks/useTitle";
import { db, storage } from "../../lib/firebase";
import { ISession } from "../../lib/model";
import { getBase64, getDomain } from "../../lib/utils";
import { useCTX } from "../../state";
import { FaRegFilePdf } from "react-icons/fa";

export default function Session() {
  useTitle("Session");

  const [sessions, setSessions] = useState<ISession[]>([]);
  const [activeSessionID, setActiveSessionID] = useState<number | null>(null);
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState("");
  const [subscribed, setSubscribed] = useState(false);

  const [rateSession, setRateSession] = useState(false);
  const [rate, setRate] = useState<{ value: number; feedback: string }>({
    value: 0,
    feedback: "",
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const unsubRef = useRef<{ [k: number]: Unsubscribe }>({});

  const {
    state: { user },
    dispatch,
  } = useCTX();

  const [endRequest, ending] = useRequest();
  const [rateRequest, rating] = useRequest();
  const [sessionsRequest, loading] = useRequest(true);
  const [sessionRequest] = useRequest();

  const activeSession = activeSessionID
    ? sessions?.find((s) => s.id === activeSessionID)
    : null;

  const onMessage = useCallback(async () => {
    setSending(true);
    setMessage("");

    const cref = collection(
      db,
      "SupportChat",
      `${activeSession?.provider.id}_support@${getDomain()}`,
      "messages"
    );

    await addDoc(cref, {
      senderMail: user?.email,
      receiverMail: activeSession?.provider.email,
      agentMail: user?.email,
      messageType: "text",
      body: message,
      imageURL: "",
      audioURL: "",
      videoURL: "",
      documentURL: "",
      sessionId: activeSession?.id,
      active: true,
      domain: getDomain(),
      timestamp: new Date().getTime().toString(),
    }).then(() => setSending(false));
  }, [message, user, activeSession]);

  const onMedia = useCallback(
    (file: UploadFile) => {
      setSending(true);

      const ftype = file.type?.split("/")[0];
      const fref = ref(
        storage,
        `SupportChat/${
          activeSession?.provider.id
        }_support@${getDomain()}/${ftype}s/${nanoid(5)}.${
          file.type?.split("/")[1]
        }`
      );

      const uploader = uploadBytesResumable(fref, file.originFileObj!);

      uploader.on("state_changed", null, null, () => {
        getDownloadURL(uploader.snapshot.ref).then((url) => {
          const cref = collection(
            db,
            "SupportChat",
            `${activeSession?.provider.id}_support@${getDomain()}`,
            "messages"
          );

          const message = {
            senderMail: user?.email,
            receiverMail: activeSession?.provider.email,
            agentMail: user?.email,
            messageType: ftype,
            body: "",
            imageURL: "",
            audioURL: "",
            videoURL: "",
            documentURL: "",
            sessionId: activeSession?.id,
            active: true,
            domain: getDomain(),
            timestamp: new Date().getTime().toString(),
          };

          if (ftype === "image") message.imageURL = url;
          else if (ftype === "audio") message.audioURL = url;
          else if (ftype === "video") message.videoURL = url;
          else if (ftype === "application") message.documentURL = url;

          addDoc(cref, message).then(() => {
            setSending(false);
          });
        });
      });
    },
    [user, activeSession]
  );

  const onEnd = () => {
    endRequest(
      "post",
      "agents/api/websession/end/",
      () => {
        setSessions((old) => {
          const result = [...old];
          const sidx = result.findIndex((s) => s.id === activeSession?.id);
          result[sidx] = { ...result[sidx], status: "F" };
          return result;
        });
      },
      {
        token: localStorage.getItem("token"),
        sessionId: activeSession?.id,
      }
    );
  };

  const onRate = () => {
    rateRequest(
      "post",
      "agents/api/webrating/",
      () => {
        setSessions((old) => {
          const result = [...old];
          const sidx = result.findIndex((s) => s.id === activeSession?.id);
          result[sidx] = {
            ...result[sidx],
            agent_rate: rate.value,
            feedback: rate.feedback,
          };
          return result;
        });
        setRate({ value: 0, feedback: "" });
        setRateSession(false);
      },
      {
        token: localStorage.getItem("token"),
        sessionId: activeSession?.id,
        rater: "agent",
        rating: rate.value,
        feedback: rate.feedback,
      }
    );
  };

  const subscribeSession = useCallback((session: ISession) => {
    const cref = collection(
      db,
      "SupportChat",
      `${session.provider.id}_support@${getDomain()}`,
      "messages"
    );

    const q = query(
      cref,
      where("sessionId", "==", session.id),
      orderBy("timestamp", "asc")
    );

    unsubRef.current[session.id] = onSnapshot(q, (snapshot) => {
      setSessions((old) => {
        const docs = snapshot.docs.map(
          (d) => ({ id: d.id, ...d.data() } as any)
        );

        const result = [...old];
        const sidx = result.findIndex((s) => s.id === session.id);
        result[sidx] = {
          ...result[sidx],
          status: docs.slice(-1)[0].active ? "A" : "F",
          messages: docs,
        };

        return result;
      });

      if (session.status !== "A") {
        unsubRef.current[session.id]();
      }
    });
  }, []);

  useEffect(() => {
    if (!subscribed && !loading) {
      const lref = collection(db, "SupportChat", "support@yunee.app", "logs");

      const q = query(
        lref,
        where("type", "==", "ASSIGN"),
        where("agentId", "==", user?.id),
        where("agentRead", "==", false)
      );

      unsubRef.current[0] = onSnapshot(q, (snapshot) => {
        for (const c of snapshot.docChanges()) {
          const log: any = { id: c.doc.id, ...c.doc.data() };

          if (c.type === "added") {
            updateDoc(c.doc.ref, { agentRead: true });

            if (!sessions.find((s) => s.id === log.sessionId)) {
              sessionRequest(
                "post",
                "agents/api/websession/",
                (res) => {
                  subscribeSession(res.data.session);
                  setSessions((old) => [res.data.session, ...old]);
                },
                {
                  token: localStorage.getItem("token"),
                  sessionId: log.sessionId,
                }
              );
            }

            break;
          }
        }
      });

      setSubscribed(true);
    }
  }, [subscribed, loading, sessions, user, subscribeSession, sessionRequest]);

  useEffect(() => {
    sessionsRequest(
      "post",
      "agents/api/websessions/",
      (res) => {
        setSessions(res.data.sessions);
        res.data.sessions.forEach(subscribeSession);
      },
      {
        token: localStorage.getItem("token"),
      }
    );

    const ref = unsubRef.current;
    return () => Object.values(ref).forEach((u) => u());
  }, [dispatch, subscribeSession, sessionsRequest]);

  useEffect(() => {
    if (activeSession?.status === "A") {
      containerRef.current?.scrollTo({
        behavior: "smooth",
        top: containerRef.current.scrollHeight,
      });
    }
  }, [activeSession]);

  if (user?.agent_status !== "A") {
    return <Navigate to={"/"} />;
  }

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <Modal
        open={rateSession}
        title="Rate"
        onCancel={() => {
          setRateSession(false);
          setRate({ value: 0, feedback: "" });
        }}
        okText="Submit"
        confirmLoading={rating}
        onOk={onRate}
      >
        <Rate
          className="mb-4"
          value={rate.value}
          onChange={(value) => setRate({ ...rate, value })}
        />
        <TextArea
          placeholder="Write feedback..."
          rows={5}
          value={rate.feedback}
          onChange={(e) => setRate({ ...rate, feedback: e.target.value })}
        />
      </Modal>
      <div className="w-full">
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <MdSupportAgent className="mr-2" /> Sessions
        </Typography.Title>
        <div className="flex">
          <List
            className="w-[30%]"
            itemLayout="horizontal"
            dataSource={sessions}
            renderItem={(item) => (
              <List.Item
                className="cursor-pointer"
                style={{
                  backgroundColor:
                    activeSessionID === item.id
                      ? "rgba(0, 0, 0, 0.02)"
                      : "initial",
                }}
                onClick={() => setActiveSessionID(item.id)}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={
                        item.provider.photo
                          ? item.provider.photo
                          : "/images/no-avatar.jpeg"
                      }
                      className="ml-2"
                    />
                  }
                  title={<span>{item.provider.name}</span>}
                  description={item.provider.email}
                />
                <Tag color={item.status === "A" ? "green" : "red"}>
                  {item.status === "A" ? "active" : "finished"}
                </Tag>
              </List.Item>
            )}
          />
          {activeSession && (
            <div className="w-[70%] border border-solid border-gray-100 mx-4 p-4">
              <div className="flex justify-between items-center h-[5vh]">
                <div className="flex">
                  <Avatar
                    src={
                      activeSession.provider.photo
                        ? activeSession.provider.photo
                        : "/images/no-avatar.jpeg"
                    }
                    className="mr-2"
                  />
                  <div className="flex flex-col">
                    <span style={{ fontSize: "0.7rem" }}>
                      {activeSession.provider.name}
                    </span>
                    <span
                      style={{ fontSize: "0.7rem" }}
                      className="text-gray-500"
                    >
                      {activeSession.provider.email}
                    </span>
                  </div>
                </div>
                <div>
                  {activeSession.status === "A" && (
                    <Button
                      loading={ending}
                      className="mr-2 text-xs"
                      icon={<MdLogout size={10} />}
                      onClick={onEnd}
                    >
                      End
                    </Button>
                  )}
                  <Button
                    icon={<MdClose size={10} />}
                    onClick={() => setActiveSessionID(null)}
                  />
                </div>
              </div>
              <div
                ref={containerRef}
                className="h-[46vh] py-4 mt-4 overflow-y-scroll"
              >
                <div className="flex flex-col justify-end">
                  {(activeSession.messages || []).map((m) => (
                    <div
                      key={m.id}
                      className="flex flex-col border border-solid border-gray-100 mt-4 p-4 w-1/2"
                      style={{
                        alignSelf:
                          user.email === m.senderMail
                            ? "flex-end"
                            : "flex-start",
                      }}
                    >
                      <span style={{ fontSize: "0.6rem" }} className="mb-2">
                        {m.senderMail}
                      </span>
                      {m.messageType === "image" ? (
                        <Image
                          src={m.imageURL}
                          alt={m.imageURL}
                          width={100}
                          height={100}
                          className="object-cover"
                        />
                      ) : m.messageType === "audio" ? (
                        <AudioPlayer
                          src={m.audioURL}
                          showJumpControls={false}
                        />
                      ) : m.messageType === "video" ? (
                        <Player
                          src={m.videoURL}
                          fluid={false}
                          width={"100%" as any}
                          height={200}
                        />
                      ) : m.messageType === "application" ? (
                        <a
                          href={m.documentURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaRegFilePdf className="text-5xl mt-2" />
                        </a>
                      ) : (
                        <span className="text-xs">{m.body}</span>
                      )}
                      <span
                        className="text-gray-500 mt-2 ml-auto"
                        style={{ fontSize: "0.7rem" }}
                      >
                        {new Date(parseInt(m.timestamp)).toLocaleTimeString()}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              {activeSession.status === "A" ? (
                <div className="h-[5vh] flex items-center">
                  <Upload
                    fileList={[]}
                    accept="image/*,audio/*,video/*,.pdf"
                    previewFile={getBase64 as any}
                    onChange={({ fileList }) => onMedia(fileList[0])}
                    className="mr-1"
                  >
                    <Button icon={<MdOutlinePermMedia size={10} />} />
                  </Upload>
                  <Input
                    placeholder="Write a message..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <Button
                    className="ml-2"
                    icon={<IoMdPaperPlane size={10} />}
                    disabled={!message}
                    loading={sending}
                    onClick={onMessage}
                  />
                </div>
              ) : (
                <div className="h-[5vh] flex items-center justify-center">
                  <span className="font-bold" style={{ fontSize: "0.7rem" }}>
                    session has finished.
                  </span>
                  {typeof activeSession.agent_rate !== "number" ? (
                    <Button
                      className="text-xs ml-2"
                      icon={<MdStarRate size={10} />}
                      onClick={() => setRateSession(true)}
                    >
                      Rate
                    </Button>
                  ) : (
                    <Rate
                      disabled
                      className="text-xs ml-2"
                      value={activeSession.agent_rate}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </PrivateRoute>
  );
}
